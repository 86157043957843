<template>
  <div class="about">

    <img style="padding-top: 20px; padding-bottom: 33px; opacity: 0.67; max-width: 150px; min-width: 150px; height: auto;" src="../assets/2logo.png" />

      <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">Our Goal</summary>
      <br/>
      <p>Our goal is to provide the highest quality prints and reproductions available anywhere with excellent customer service.</p>
      <p>Start by getting your artwork captured using our flatbed scanner or digital camera. Our team will decide which format is best suited to your artwork.</p>
      <br>
      <p>If you aren't 100% happy with your experience we will redo your print or give you your money back.</p>
      <br/>
      </details>
      
      <br/>

      <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">Our Philosophy</summary>
      <br/>
      <p>We love art and that is why we believe in only using the highest quality materials available in the giclée printing market. We believe everything we make should last for generations to come. All of our prints are made using genuine Canon or Epson ink on archival papers from Epson and Lexjet.</p>
      <br>
      </details>
    
      <br>

      <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">About the Owner</summary>
      <br/>
      <p>Chris is a fine art photographer who completed his Bachelor of Arts degree at Brooks Institute in Santa Barbara, California. After graduating he learned the ins and outs of printmaking at a prestigious Los Angeles post-production facility. He then started his own Colorado based fine art reproduction and printing facility in 2006.<br><br>His goal at The Painted Pixel is to provide a print studio where artists and photographers can build a strong working relationship with their printmaker while receiving the best reproductions of their artwork possible. Every order is meticulously carried out as if it were a personal project.<br><br>We are a small company and believe that is a benefit to you. Artists often interact directly with the printmaker that will be creating their reproductions. If you have a special request then you are welcome to communicate that to your printmaker.</p>
      <br>
      </details>
    
      <br>

      <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">Rewards Program</summary>
      <br/>
      <p>We want to help you get the most value out of our services so you can continue to grow your business.</p>
      <br>
      <p>Each dollar spent gives you one reward point, and for every 100 points accumulated, you will receive $10 off future purchases.</p>
      <br>
      <p>For example, you order a restock of some prints and spend $500, the next time you place an order, you will be prompted at checkout whether you wish to get $50 off the total.</p>
      <br>
      <p>Sign up is easy. The first time you check out with us, you will be prompted to join the rewards program, and will automatically be integrated with your account going forward.</p>
      <br>
      <p>Points expire after one year.</p>
      <br>
      </details>
    
      <br>

      <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.85; font-size: 1.0em; color: rgba(242, 157, 75, 1.0);">Request Consultation</summary>
      <br/>
      <p>
      The technical aspects of fine art printing can be overwhelming. While not difficult, once you understand the properties and limitations of this process, there are some key considerations that will make your work translate stronger as a fine art print. Everything from how to prepare your files, finding appropriate ratios to create size variations of your work, to considerations of final presentation, such as sizing your print to a custom matte and frame or determining resolution in relation to viewing distance.
      </p>
      <br>
      <p>
      We have encountered these considerations many times, and whether you are new to this process, or undertaking a project that requires a lot of intricacies or customization, we are happy to guide you through what our experience has shown us yield the best results.
      </p>
      <br>
      <p>
      We are available for either video calls, for quick and convenient solutions to answer most questions in the digital realm, or in-person consultation at our shop, where we can run proofs and make adjustments to your physical prints.
      </p>
      <h5>
      Let us known in the form below:
      </h5>
      <a target="_blank" style="
      background-color: transparent; 
      border: 1px solid #546476;
      color: #2c3e50; 
      height: 40px; 
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px; 
      line-height: 38px; 
      padding: 0 10px; 
      border-radius: 8px; 
      font-weight: 500; 
      font-size: 14px; 
      cursor: pointer; display: 
      inline-block;"
      href="https://squareup.com/appointments/buyer/widget/2zuawdth4v09ps/ESPTFE637VPJ5">Schedule Consultation
      </a>
      <br/>
      </details>
      <br>
  </div>
  <br>
</template>

